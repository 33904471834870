import React, { useState } from "react";

const FinanceCalculator = () => {
  const [price, setPrice] = useState(""); // Vehicle price
  const [loanTerm, setLoanTerm] = useState("12"); // Default to 12 months
  const [downPayment, setDownPayment] = useState(""); // Down payment
  const [tradeInValue, setTradeInValue] = useState(""); // Trade-in value
  const [markupRate, setMarkupRate] = useState(0); // Markup rate, default to 0
  const [totalDownPayment, setTotalDownPayment] = useState(null);
  const [monthlyPayment, setMonthlyPayment] = useState(null);

  // Calculate the monthly payment
  const calculatePayment = () => {
    const priceValue = parseFloat(price);
    const downPaymentValue = parseFloat(downPayment);
    const tradeInValueValue = parseFloat(tradeInValue);

    const months = parseInt(loanTerm);
    const markup = parseFloat(markupRate) / 100; // Convert markup rate to percentage

    // Validation: Ensure all required fields are filled correctly
    if (isNaN(priceValue) || priceValue <= 0) {
      alert("Please enter a valid vehicle price.");
      return;
    }
    if (isNaN(downPaymentValue) || downPaymentValue < 0) {
      alert("Please enter a valid down payment.");
      return;
    }
    if (isNaN(tradeInValueValue) || tradeInValueValue < 0) {
      alert("Please enter a valid trade-in value.");
      return;
    }
    
    if (isNaN(months) || months <= 0) {
      alert("Please select a valid loan term.");
      return;
    }
    if (isNaN(markup) || markup < 0) {
      alert("Please enter a valid markup rate.");
      return;
    }

    // Calculate principal
    const principal = priceValue - downPaymentValue - tradeInValueValue;
    if (principal <= 0) {
      alert("The principal amount after down payment and trade-in must be positive.");
      return;
    }

    // Monthly interest rate calculation
    const interestRate = markup / 12; // Monthly markup rate

    // Monthly payment formula (loan amortization formula)
    const monthly = (principal * interestRate) / (1 - Math.pow(1 + interestRate, -months));
    setMonthlyPayment(monthly.toFixed(0));

    // Calculate total down payment including trade-in and down payment 
    const totalDown = downPaymentValue + tradeInValueValue;
    setTotalDownPayment(totalDown.toFixed(0));
  };

  return (
    <div className="w-full mx-auto px-5 py-10 text-gray-600 dark:text-gray-300 bg-white dark:bg-gray-900 rounded-md shadow-lg">
      <div className="text-center max-w-xl mx-auto">
        <h1 className="text-3xl sm:text-4xl font-bold mb-5 text-gray-900 dark:text-gray-100">
          Suzuki Finance Calculator
        </h1>
      </div>

      <div className="max-w-4xl mx-auto md:flex md:space-x-6">
        {/* Form Block */}
        <div className="w-full bg-gray-50 dark:bg-gray-800 rounded-md px-8 py-6 space-y-6 shadow-md">
          <div className="flex flex-col space-y-3">
            {/* Vehicle Price */}
            <div className="flex flex-col">
              <label htmlFor="price" className="font-medium text-gray-700 dark:text-gray-300">
                Vehicle Price (PKR)
              </label>
              <input
                type="number"
                id="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="w-full px-4 py-2 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-300 border border-gray-300 dark:border-gray-600"
                placeholder="Enter vehicle price"
              />
            </div>

            {/* Markup Rate and Loan Term */}
            <div className="flex space-x-6">
              {/* Markup Rate */}
              <div className="w-full flex flex-col">
                <label htmlFor="markup_rate" className="font-medium text-gray-700 dark:text-gray-300">
                  Markup Rate (%)
                </label>
                <input
                  type="number"
                  id="markup_rate"
                  value={markupRate}
                  onChange={(e) => setMarkupRate(e.target.value)}
                  className="w-full px-4 py-2 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-300 border border-gray-300 dark:border-gray-600"
                  placeholder="Enter markup rate"
                />
              </div>

              {/* Loan Term */}
              <div className="w-full flex flex-col">
                <label htmlFor="loan_term" className="font-medium text-gray-700 dark:text-gray-300">
                  Loan Term (Months)
                </label>
                <select
                  id="loan_term"
                  value={loanTerm}
                  onChange={(e) => setLoanTerm(e.target.value)}
                  className="w-full px-4 py-2 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-300 border border-gray-300 dark:border-gray-600"
                >
                  <option value="12">12 months</option>
                  <option value="24">24 months</option>
                  <option value="36">36 months</option>
                  <option value="48">48 months</option>
                  <option value="60">60 months</option>
                  <option value="72">72 months</option>
                  <option value="84">84 months</option>
                </select>
              </div>
            </div>

            {/* Down Payment and Trade-In Value */}
            <div className="flex space-x-6">
              {/* Down Payment */}
              <div className="w-full flex flex-col">
                <label htmlFor="down_payment" className="font-medium text-gray-700 dark:text-gray-300">
                  Down Payment (PKR)
                </label>
                <input
                  type="number"
                  id="down_payment"
                  value={downPayment}
                  onChange={(e) => setDownPayment(e.target.value)}
                  className="w-full px-4 py-2 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-300 border border-gray-300 dark:border-gray-600"
                  placeholder="Enter down payment"
                />
              </div>

              {/* Trade-In Value */}
              <div className="w-full flex flex-col">
                <label htmlFor="value_tradein" className="font-medium text-gray-700 dark:text-gray-300">
                  Trade-In Value (PKR)
                </label>
                <input
                  type="number"
                  id="value_tradein"
                  value={tradeInValue}
                  onChange={(e) => setTradeInValue(e.target.value)}
                  className="w-full px-4 py-2 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-300 border border-gray-300 dark:border-gray-600"
                  placeholder="Enter trade-in value"
                />
              </div>
            </div>

            {/* Calculate Button */}
            <button
              onClick={calculatePayment}
              className="w-full py-3 px-6 bg-red-600 hover:bg-red-700 text-white font-semibold rounded-md transition-colors"
            >
              Calculate Payments
            </button>

            {/* Monthly Payment Result */}
            {monthlyPayment !== null && (
              <div className="mt-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                  Estimated Monthly Payment (PKR):
                </h2>
                <p className="text-lg text-gray-900 dark:text-gray-300">{monthlyPayment}</p>
              </div>
            )}

            {/* Total Down Payment Result */}
            {totalDownPayment !== null && (
              <div className="mt-6">
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                  Total Payment (PKR):
                </h2>
                <p className="text-lg text-gray-900 dark:text-gray-300">{totalDownPayment}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceCalculator;
